import { createApp } from 'vue' // функция создания application instance
import App from './App.vue'     // импорт корневого компонента App.vue
import router from './router'   // импорт маршрутизатора
import store from './store'     // импорт хранилища

const app = createApp(App)      // создается application instance
app.use(store).use(router)      // установка плагинов store и router

// FontAwesome:
// -----------
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronUp, faChevronDown, faPause, faPlay, faStop,
        faCircle, faCircleNotch, faRedo, faPlus, faForward,
        faCaretUp } from '@fortawesome/free-solid-svg-icons'

library.add(faChevronUp, faChevronDown, faPause, faPlay, faStop,
            faCircle, faCircleNotch, faRedo, faPlus, faForward,
            faCaretUp)

// регистрируем глобально компонент FontAwesomeIcon чтобы Vue знал его 
// расположение, когда встретит его в template. 
app.component('fa-icon', FontAwesomeIcon)

// emitter:
// --------
import mitt from 'mitt'         // импорт эмиттера
const emitter = mitt()
// application предоставляет объект config, в котором содержится 
// конфигурация настроек приложения. В объекте config содержится объект
// globalProperties, который может быть использован для регистрации 
// глобальных свойств, которые будут доступны для всех компонентов.
// добавим в globalProperties созданный выше объект эмиттера:
app.config.globalProperties.emitter = emitter

// монтирование приложения в блок с id='app' файла index.html
app.mount('#app')
