import { createRouter, createWebHistory } from 'vue-router'
import LoginReg from '../views/LoginReg.vue'

const routes = [
  {
    path: '/login',
    name: 'Login Signin',
    component: LoginReg,
    // component: () => import('../views/Login.vue')
  },
  
  {
    path: '/',
    name: 'Tasks',
    component: () => import('../views/MaxwellView.vue')
  },

  {
    path: '/tasks',
    name: 'Task List',
    component: () => import('../views/MaxwellView.vue')
  },
  // {
  //   path: '',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

export default router
