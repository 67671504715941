import urls from './urls'
import axios from 'axios'

export const laborState = {
    tasks: [],
    activities: [],
    selectedTasks: [],
    currentTaskId: null,
}
export const laborMutations = {

    updateTask(state, payload) {
       let task = state.tasks.find(item => item.id == payload.id)
       let fieldNames = Object.keys(payload)
       for (let index in fieldNames) { 
           let field = fieldNames[index]
           task[field] = payload[field]
       }
   },
   updateTaskList(state, tasks) {
       state.tasks = tasks
   },
}
export const laborActions = {
    
    // promise нужен для случая, когда после получения данных нужно вызвать
    // еще другое действие в компоненте методом then или catch
    updateTaskAct(ctx, payload) {
        return new Promise((resolve, reject) => {
            ctx.commit('updateTask', payload)
            axios.patch(urls.taskURL + payload.id + '/', JSON.stringify(payload))
            .then(response => { resolve(response) })
            .catch(error => { reject(error) })
        })
    },

    getTaskListAct(ctx) {
        console.log("labor: getTaskListAct")
        axios.get(urls.taskURL).then(response => 
            ctx.commit('updateTaskList', response.data))
            .catch(error => console.log(error))
    },

    getActivityList(ctx) {
        axios.get(urls.activityURL).then(response => ctx.commit('updateActivityList', response.data))
        .catch(error => console.log(error))
    },
    
    // это образец использования XMLHttpRequest для запросов
    // -----------------------------------------------------
    // removeTaskAct(ctx, payload) {
    //     // ctx.commit('removeTask', payload)
    //     let xhr = new XMLHttpRequest()
    //     xhr.open('DELETE', ctx.state.taskURL + payload + '/', true)
    //     xhr.onreadystatechange = function() {
    //         if (xhr.readyState == 4) {
    //             if (xhr.status == 204) {
    //                 console.log('Task with id=' + payload + ' is removed')
    //             }
    //             else {
    //                 console.log('status:', xhr.status, xhr.statusText)
    //             }
    //         }
    //     }
    //     xhr.setRequestHeader('Content-Type', 'application/json')
    //     xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('key'))
    //     xhr.send()
    // },

    removeTaskAct(ctx, payload) {
        let url = urls.taskURL
        // удаление одной задачи
        if (payload.mode=='current') {
            url = url + payload.id + '/'
            delete payload.mode
            axios.delete(url)
            .then(() => { ctx.dispatch('getTaskListAct') })
            .catch(() => console.log('catch'))
        // удаление каскадом вместе с подзадачами
        } else if (payload.mode=='cascade') {
            delete payload.mode
            axios.patch(url, JSON.stringify(payload))
            .then(() => { ctx.dispatch('getTaskListAct') })
            .catch(() => console.log('catch'))
        }
    },

    createTaskAct(ctx, payload) {
        return new Promise((resolve, reject) => {
            axios.post(urls.taskURL, JSON.stringify(payload))
            .then(response => {
                resolve(response)
                // ctx.commit("editAction", response)
                ctx.dispatch('getTaskListAct')
            }).catch(error => reject(error))
        })
    },
}
export const laborGetters = {
    
    allTasks(state) {
        return state.tasks
    },
    currentTask(state) {
        return state.tasks.find(item => item.id == state.currentTaskId)
    },
    decompose: state => task_id => {
    // раскладывает секунды на года, месяцы, дни, часы, минуты, секунды
    // принимает объект id задачи, и возвращает два объекта :
    // dt - содержит числовые значения, dtStr - строковые двухразрядные значения
        console.log('decompose', task_id)
        let task = state.tasks.find(item => item.id == task_id)
        let tempSeconds = task.totalSeconds
        if (typeof(tempSeconds) !== 'number') { tempSeconds = 0 }

        let dt = {}
        dt.years = Math.floor(tempSeconds / 31104000)
        tempSeconds -= dt.years * 31104000
        dt.months = Math.floor(tempSeconds / 2592000)
        tempSeconds -= dt.months * 2592000
        dt.days = Math.floor(tempSeconds / 86400)
        tempSeconds -= dt.days * 86400
        dt.hours = Math.floor(tempSeconds / 3600)
        tempSeconds -= dt.hours * 3600
        dt.minutes = Math.floor(tempSeconds / 60)
        dt.seconds = tempSeconds - dt.minutes * 60

        let dtStr = {}
        if (dt.years < 10) { dtStr.years = '0' + dt.years }
            else { dtStr.years = dt.years}
        if (dt.months < 10) { dtStr.months = '0' + dt.months }
            else { dtStr.months = dt.months}
        if (dt.days < 10) { dtStr.days = '0' + dt.days }
            else { dtStr.days = dt.days}
        if (dt.hours < 10) { dtStr.hours = '0' + dt.hours }
            else { dtStr.hours = dt.hours}
        if (dt.minutes < 10) { dtStr.minutes = '0' + dt.minutes }
            else { dtStr.minutes = dt.minutes}
        if (dt.seconds < 10) { dtStr.seconds = '0' + dt.seconds }
            else { dtStr.seconds = dt.seconds}
        return {dt, dtStr}
    },
}
