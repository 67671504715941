import { createStore } from 'vuex'

import { authState, authMutations, authActions, authGetters } from './auth-store'
import { laborState, laborMutations, laborActions, laborGetters } from './labor-store'
import { timelapseState, timelapseMutations, timelapseActions, timelapseGetters } from './timelapse-store'

const s = { ...authState, ...timelapseState, ...laborState, }

export default createStore({
    state() {
        return s
    },
    mutations: { ...authMutations, ...laborMutations, ...timelapseMutations, },
    actions: { ...authActions, ...laborActions, ...timelapseActions, },
    getters: { ...authGetters, ...laborGetters, ...timelapseGetters, },
})
