<template>
<form id="login-form" action="" method="post">
    <p class="visible"><span>username: </span><input name="username" id="username" v-model="username" placeholder="username"></p>
    <p v-if="registration" class="hidden">email: <input name="email" id="email"  v-model="email" placeholder="email"></p>
    <p class="visible">password: <input type="password" name="password" id="password1" v-model="password1" placeholder="password"></p>
    <p v-if="registration" class="hidden">password: <input type="password"  name="password" id="password2" v-model="password2" placeholder="password"></p>
    <p class="visible">
        <input id="submit" type="submit" @click="submit($event)" :value="inputValue">
        <a id="switcher" @click="swithcer" href="#" >{{ switchText }}</a>
    </p>
    <p>{{ deny }}</p>
</form>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'LoginReg',
    data() {
        return {
            registration: false,
            switchText: 'Registration',
            inputValue: 'LogIn',
            // username: 'Drosophila',
            // email: 'email@mail.com',
            // password1: 'micromatosis',
            // password2: 'micromatosis',
            username: '',
            email: '',
            password1: '',
            password2: '',
            deny: '',
        }
    },
    mounted() {
        // инструкции, вызываемые при монтировании компонента:
        // если не авторизован - перенаправить на страницу login
        console.log('Is Authenticated', this.$store.getters.isAuthenticated)
        if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch('getTaskListAct')
        this.$store.dispatch('getActivityList')
        // this.$store.dispatch('getCurrentTaskAct')
        this.$router.push({ path: '/tasks' })
        }
    },
    methods: {
        swithcer() {
            this.registration = !this.registration
            if (this.registration) {
                this.switchText = 'Authorization'
                this.inputValue = 'SignIn'
            } else {
                this.inputValue = 'LogIn'
                this.switchText = 'Registration'
            }
        },
        submit(e) {
            e.preventDefault()
            let payload = {}
            payload.isRegistration = this.registration
            if (this.registration) {
                payload.username = this.username.toLowerCase()
                payload.email = this.email.toLowerCase()
                payload.password1 = this.password1.toLowerCase()
                payload.password2 = this.password2.toLowerCase()
                this.authAct(payload, this.registration).then(() => {
                this.$store.dispatch('getTaskListAct')
                this.$store.dispatch('getActivityList')
                this.$router.push('/tasks') }).catch((err) => {
                    for (var key in err.response.data) {
                        this.deny = ''
                        this.deny += err.response.data[key]
                    }
                })
            } else {
                payload.username = this.username.toLowerCase()
                payload.password = this.password1.toLowerCase()
                this.authAct(payload, this.registration).then(() => {
                this.$store.dispatch('getTaskListAct')
                this.$store.dispatch('getActivityList')
                this.$router.push('/tasks') }).catch((err) => {
                    for (var key in err.response.data) {
                        this.deny = ''
                        this.deny += err.response.data[key]
                    }
                })
            }
        },

        ...mapActions([ 'authAct', 'createTaskAct', 'getTaskListAct' ]),
    },
}

</script>

<style scoped lang="scss">

:root {
    --blackgray: #2A2822;
    --coalgray: #46423E;
    --lowgray: #65625D;
    --highgray: #DEDAD6;
}
#login-form {
    position: relative;
    max-width: 330px;
    display: grid;
    // margin: auto auto;
    grid-gap: 10px;
    padding: 30px;
    background-color: var(--coalgray);
    box-shadow: 1px 1px 20px 5px black;
    border-radius: 5px; 
    align-self: center;
    justify-self: center;
}

.visible, .hidden {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: 20px;
}

#username, #email, #password2, #password1 {
    border-radius: 3px;
    background-color: var(--highgray);
}

.visible a:last-child {
    display: grid;
    justify-items: end;
}

</style>