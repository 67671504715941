import urls from './urls'
import axios from 'axios'
import { nextTick } from 'vue';

export const timelapseState = {
    actionStruct : {},      // object {date: [actions]}
    actionTypes: {},        // object {id: verbal}
    actionSubTypes: {},     // object {id: verbal}

    creatingAction: false,
    currentActionID: null,
    startDate: "",
    endDate: "",
    // currentActionDate: null,

}
export const timelapseMutations = {

    setDateRange(state, payload) {
        console.log("mutation: setDateRange", payload)
        state.startDate = payload.start_date
        state.endDate = payload.end_date
    },

    addActionToStruct(state, payload) {
        console.log("mutation: addActionToStruct", payload)
        const date = payload.date
        let actionList = state.actionStruct[date]
        if (!actionList) {
            actionList = []
        }
        actionList.push(payload)
        const newActionStruct = { ...state.actionStruct }
        newActionStruct[date] = actionList
        state.actionStruct = newActionStruct
    },

    patchActionID(state, payload) {
        console.log("mutation: patchActionID", payload.id)
        for (let action of state.actionStruct[payload.date]) {
            if (!action.id) {
                action.id = payload.id
                break
            }
        }
    },

    setCurrentActionID(state, id) {
        state.currentActionID = id
    },
    // setCurrentActionDate(state, date) {
    //     state.currentActionDate = date
    // },
    setActionStruct(state, payload) {
        console.log("mutation: setActionStruct")
        state.actionStruct = {}
        for (let act of payload) {
            if (act.date in state.actionStruct) { state.actionStruct[act.date].push(act) } 
            else { state.actionStruct[act.date] = [act] }
        }
    },

    orderingActions(state, date) {
        const actions = state.actionStruct[date]
        console.log("mutation: orderingActions with date", date)
        actions.sort((a, b) => {
            if (a.startTime < b.startTime) return -1
            if (a.startTime > b.startTime) return  1
        })
        actions.sort((a, b) => a.isAfterMidnight - b.isAfterMidnight)
        const struct = { ...state.actionStruct }
        struct[date] = actions
        state.actionStruct = struct
    },

    updateAction(state, payload) {
        const actions = state.actionStruct[payload.date]
        console.log("mutation: updateAction with id", payload.id)
        for (let i = 0; i < actions.length; i++) {
            if (actions[i].id === payload.id) {
                for (const key in payload) {
                    actions[i][key] = payload[key]
                }
            }
        }
    },
    updateDate(state, payload) {
        console.log("mutation: update updateDate", payload)
        
        const oldDate = payload.oldDate
        const newDate = payload.newDate
        const newActionStruct = { ...state.actionStruct }
        const actionList = [...state.actionStruct[oldDate]]
        for (const action of actionList) {
            action.date = newDate
        }
        delete newActionStruct[oldDate]
        newActionStruct[newDate] = actionList
        state.actionStruct = newActionStruct
    },

    updateActType(state, data){
        state.actionTypes[data.id] = data.verbal
    },
    updateActSubType(state, data){
        state.actionSubTypes[data.id] = data.verbal
    },
    deleteEmptyDays(state) {
        for (const key in state.actionStruct) {
            if (state.actionStruct[key].length == 0) {
                delete state.actionStruct[key]
            }
        }
    },
    refreshActStruct(state) { 
        console.log("mutation: refreshActStruct")
        state.actionStruct = { ...state.actionStruct }
    },

    deleteAction(state, id) {
        for (let key in state.actionStruct) {
            const index = state.actionStruct[key].findIndex(action => action.id == id)
            if (index != -1) {
                console.log("mutation: delete id =", id)
                state.actionStruct[key].splice(index, 1)
                break
            }
        }
        state.actionStruct = { ...state.actionStruct }
    },

    setActTypes(state, payload) {
        state.actionTypes = {}
        for (let item of payload) {
            state.actionTypes[item.id] = item.verbal
        }
        state.actionTypes[null] = ''
    },
    setActSubTypes(state, payload) {
        state.actionSubTypes = {}
        for (let item of payload) {
            state.actionSubTypes[item.id] = item.verbal
        }
        state.actionSubTypes[null] = ''
    },
}
export const timelapseActions = {

    // Actions
    // ------------

    async setDateRangeAct(ctx, payload) {
        ctx.commit("setDateRange", payload)
    },

    async getActionListAct(ctx, payload) {
        console.log("action: getActionListAct")
        try {
            const response = await axios.get(urls.actionURL, payload)
            ctx.commit("setActionStruct", response.data)
            ctx.commit("setDateRange", payload.params)
        } catch (error) {
            console.error("Error fetching action list:", error)
        }
    },
    async createActionAct(ctx, payload) {
        ctx.commit("addActionToStruct", payload)
        ctx.commit("orderingActions", payload.date)
        let promise
        axios.post(urls.actionURL, JSON.stringify(payload))
            .then((response) => { 
                console.log("action: createActionAct")
                // докидываем созданному действию id, присвоенное на сервере 
                const response_payload = { date: response.data.date,
                                             id: response.data.id } 
                ctx.commit("patchActionID", response_payload)
            })
            .catch(error => console.log(error))
        return promise
    },

    async updateDateAct(ctx, payload) {
        // сначала обновляется хнанилище, затем отправляются обновления на сервер
        await ctx.commit("updateDate", payload)
    },

    async updateActionAct(ctx, payload) {   // payload is action
        // console.log("payload", payload)
        ctx.commit("updateAction", payload)
        nextTick(() => {
            ctx.commit("orderingActions", payload.date)
        })
        return axios.patch(urls.actionURL + payload.id + '/', JSON.stringify(payload))
            .then(response => {
                console.log("action: updated id = ", response.data.id)
                ctx.commit("updateAction", response.data)
                })
            .catch(error => console.log(error) )
    },
    async deleteActionAct(ctx, id) {
        ctx.commit("deleteAction", id)
        ctx.commit("deleteEmptyDays")
        ctx.commit("refreshActStruct")
        return axios.delete(urls.actionURL + id + '/')
            .then((response) => 
                console.log("action: updated id = ", id, "response status:", response.status)).
            catch(error => console.log(error))
    },


    // ActionTypes
    // -----------

    async getActTypes(ctx) {
        return axios.get(urls.actTypeURL)
            .then(response => ctx.commit("setActTypes", response.data))
            .catch(error => console.log(error))
    },
    async createActType(ctx, payload) {
        return axios.post(urls.actTypeURL, JSON.stringify(payload))
            .then(response => ctx.commit("updateActType", response.data))
            .catch(error => console.log(error))
    },
    async updateActType(ctx, payload) {
        return axios.patch(urls.actTypeURL + payload.id + '/', JSON.stringify(payload))
            .then(response => ctx.commit("updateActType", response.data))
            .catch(error => console.log(error))
    },
    async deleteActionTypeAct(ctx, id) {
        return axios.delete(urls.actTypeURL + id + '/')
            .then(() => ctx.dispatch("getActTypes"))
            .catch(error => console.log(error))
    },

    // Action SubTypes
    // ---------------

    async getActSubTypes(ctx) {
        return axios.get(urls.actSubTypeURL)
            .then(response => ctx.commit("setActSubTypes", response.data))
            .catch(error => console.log(error))
    },
    async createActSubType(ctx, payload) {
        return axios.post(urls.actSubTypeURL, JSON.stringify(payload))
            .then(response => ctx.commit("updateActSubType", response.data))
            .catch(error => console.log(error))
    },
    async updateActSubType(ctx, payload) {
        return axios.patch(urls.actSubTypeURL + payload.id + '/', JSON.stringify(payload))
            .then(response => ctx.commit("updateActSubType", response.data))
            .catch(error => console.log(error))
    },
    async deleteActionSubTypeAct(ctx, id) {
        return axios.delete(urls.actSubTypeURL + id + '/')
            .then(() => ctx.dispatch("getActSubTypes"))
            .catch(error => console.log(error))
    },
}

// Getters
// -------
export const timelapseGetters = {
    actionDays(state) {
        return Object.keys(state.actionStruct)
    }
}