<template>
  <!-- <div id="nav"> -->
    <!-- <router-link to="/static/vue/login">Login</router-link> -->
    <!-- <router-link to="/">Login</router-link> -->
    <!-- <router-link to="/">Tasks</router-link> | -->
  <!-- </div> -->
  <router-view/>
  <!-- <LoginReg/> -->
  <!-- <TaskManager/> -->
</template>


<script>

// import LoginReg from './views/LoginReg.vue';
// import TaskManager from './views/TaskManager.vue';

export default {
    name: "App",
    data: () => ({}),
    mounted() {
        // инструкции, вызываемые при монтировании компонента:
        
        setInterval(() => {
            this.emitter.emit("second-tick", () => { });
        }, 1000);

        // если не авторизован - направить на страницу login
        console.log('Is Authenticated: ', this.$store.getters.isAuthenticated)
        if (!this.$store.getters.isAuthenticated) {
        this.$router.push({ path: '/login' })
        } else { // иначе направить на страницу tasks
        this.$store.dispatch('getTaskListAct')
        // this.$store.dispatch('getActTypes').then(() => {
        //   this.$store.dispatch('getActSubTypes'). then(() => {
        //     this.$store.dispatch('getActionListAct')
        //   })
        // })
        this.$router.push({ path: '/tasks' })
        }
    },

}
</script>


<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: silver;
}

:root {
    --blackgray: #2A2822;
    --coalgray: #46423E;
    --lowgray: #65625D;
    --highgray: #DEDAD6;
}
/* Убираем внутренние отступы */
ul, ol {
  padding: 0;
  list-style: none;
}
html { height: 100%; }
body { min-height: 100%; }
body, h1, h2, h3, h4, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
  color: silver;
}

a {
    text-decoration-line: none;
    color: silver;
    transition: 0.1;
}
body {
    background-color: var(--blackgray);
    display: grid;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: silver;
    padding: 0;
}
#app {
  display: grid;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: silver;
  /* margin-top: 60px; */
  background-color: var(--blackgray);
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: silver;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.overlay {
    z-index: 10;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
button {
  background-color: var(--lowgray);
  border: 1px solid var(--lowgray);
  color: silver;
  border: none;
}
button:hover, button:focus {
  outline: none;
  box-shadow: inset 0 0 5px  rgb(177, 170, 129);
}

.add-btn {
    margin-top: 4px;
    width: 100%;
}

</style>
