import urls from './urls'
import axios from 'axios'

function setAxios(token) {
    // задаются заголовки 'Content-Type' для всех axios запросов:
    axios.defaults.headers.get['Content-Type'] = 'application/json'
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.headers.patch['Content-Type'] = 'application/json'
    axios.defaults.headers.put['Content-Type'] = 'application/json'
    axios.defaults.headers.delete['Content-Type'] = 'application/json'
    if (token) {
        // задаются заголовки 'Authorization' для всех axios запросов:
        axios.defaults.headers.get['Authorization'] = 'Token ' + token
        axios.defaults.headers.post['Authorization'] = 'Token ' + token
        axios.defaults.headers.patch['Authorization'] = 'Token ' + token
        axios.defaults.headers.put['Authorization'] = 'Token ' + token
        axios.defaults.headers.delete['Authorization'] = 'Token ' + token
    }
}

const token = localStorage.getItem('key')
setAxios(token)

export const authState = {
    token: localStorage.getItem('key') || '',
    authStatus: '',
}
export const authMutations = {
    authRequest(state) { state.authStatus = 'loading' },
    authSuccess(state, token) { 
        state.authStatus = 'success'
        state.token = token
    },
    authError(state) { state.authStatus = 'error' },
    logOut(state) {
        state.tasks = []
        state.activities = []
        state.token = '' 
    },
}
export const authActions = {
    // Дейстие для выполнения регистрации/авторизации:
    authAct(ctx, payload) {
        return new Promise((resolve, reject) => {
            let isRegistration = payload.isRegistration
            delete payload.isRegistration
            ctx.commit('authRequest')
            if (isRegistration) {
                console.log('registration')
                axios.post(urls.signinURL, payload)
                    .then(response => {
                    const token = response.data.key
                    localStorage.setItem('key', token)
                    ctx.commit('authSuccess', token)
                    resolve(response)
                    setAxios(token)
                }).catch(err => {
                    ctx.commit('authError', err)
                    localStorage.removeItem('key')
                    reject(err)
                })
            } else {
                console.log('authorization')
                axios.post(urls.loginURL, payload)
                .then(response => {
                    const token = response.data.key
                    localStorage.setItem('key', token)
                    ctx.commit('authSuccess', token)
                    setAxios(token)
                    resolve(response)
                }).catch(error => {
                    ctx.commit('authError', error)
                    localStorage.removeItem('key')
                    reject(error)
                })
            }
        })
    },

    // Действие для сброса ключа
    logOutAct(ctx) {
        return new Promise((resolve) => {
            console.log('logout')
            ctx.commit('logOut')
            localStorage.removeItem('key')
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },
}
export const authGetters = {
    // гетер возвращает true, если token имеется:
    isAuthenticated: state => !!state.token,
}