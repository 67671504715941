// const domain = 'http://localhost:8000/'
const domain = 'https://ordevoir.ru/'
// const domain = 'http://wernadz5.beget.tech/'

const authURLs = {
    loginURL:'rest-auth/login/',
    signinURL: 'rest-auth/registration',
}
const laborURLs = {
    taskURL: 'labor/tasks/',
    activityURL: 'labor/activities/',
    currentTaskURL: 'labor/state/',
}
const timelapseURLs = {
    actionURL: 'timelapse/actions/',
    actTypeURL: 'timelapse/acttypes/',
    actSubTypeURL: 'timelapse/actsubtypes/',
}

const urls = {...authURLs, ...timelapseURLs, ...laborURLs}

for (const key in urls) {
    urls[key] = domain + urls[key]
}

export default urls 